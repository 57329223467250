<template>
  <div>

    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="userData === undefined"
    >
      <h4 class="alert-heading">
        Error fetching user data
      </h4>
      <div class="alert-body">
        No user found with this user id. Check
        <b-link
          class="alert-link"
          :to="{ name: 'apps-users-list'}"
        >
          User List
        </b-link>
        for other users.
      </div>
    </b-alert>

    <div v-if="userData">
      <!-- First Row -->
      <b-row>
        <b-col
          cols="12"
          lg="12"
        >
          <user-view-user-info-card
            :user-data="userData"
            :permissions-data="permissionsData"
          />
        </b-col>
      </b-row>

    </div>
    <div
      v-else
      class="text-center text-primary my-2"
    >
      <b-spinner
        class="align-middle mr-1"
      />
      <strong>Загрузка...</strong>
    </div>

  </div>
</template>

<script>
import store from '@/store'
import router from '@/router'
import { ref, onUnmounted } from '@vue/composition-api'
import {
  BRow, BCol, BAlert, BLink, BSpinner,
} from 'bootstrap-vue'
import userStoreModule from '../userStoreModule'
import UserViewUserInfoCard from './UserViewUserInfoCard.vue'

export default {
  components: {
    BRow,
    BCol,
    BAlert,
    BLink,
    BSpinner,

    // Local Components
    UserViewUserInfoCard,

  },
  setup() {
    const userData = ref(null)
    const permissionsData = ref(null)

    const USER_APP_STORE_MODULE_NAME = 'app-user'

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    store.dispatch('app-user/getUserIdGroup', router.currentRoute.params.id)
      .then(response => {
        userData.value = response.data
        permissionsData.value = response.data.userGroups.map(item => ({
          project: item.UsersGroups.Project.name,
          balance: item.UsersGroups.Project.balance,
          role: item.name,
          permissions: JSON.parse(item.permissions),
        }))
      })
      .catch(error => {
        if (error) {
          userData.value = undefined
          console.log(error)
        }
      })
    return {
      userData,
      permissionsData,
    }
  },
}
</script>

<style>

</style>
