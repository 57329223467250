<template>
  <div>
    <b-card>
      <b-row>
        <!-- User Info: Left col -->
        <b-col
          cols="12"
          xl="6"
          class="d-flex justify-content-between flex-column"
        >
          <!-- User Avatar & Action Buttons -->
          <div class="d-flex justify-content-start">
            <b-avatar
              :src="userData.avatar"
              :text="avatarText(userData.fullName)"
              :variant="`light-${resolveRoleVariant(userData.id)}`"
              size="104px"
              rounded
            />
            <div class="d-flex flex-column ml-1">
              <div class="mb-1">
                <h4>
                  {{ userData.firstName }} {{ userData.lastName }}
                </h4>
              </div>
              <div class="d-flex flex-wrap mt-1">
                <b-button
                  :to="{ name: 'apps-users-edit', params: { id: userData.id } }"
                  variant="primary"
                  :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                >
                  Изменить
                </b-button>
                <b-button
                  variant="outline-danger"
                  class="ml-1"
                  :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                >
                  Удалить
                </b-button>
                <b-button
                  variant="outline-secondary"
                  class="ml-1"
                  :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                  @click="$router.go(-1)"
                >
                  Отмена
                </b-button>
              </div>
            </div>
          </div>
        </b-col>

        <!-- Right Col: Table -->
        <b-col
          cols="12"
          xl="6"
        >
          <table class="mt-2 mt-xl-0 w-100">
            <tr>
              <th class="pb-50">
                <feather-icon
                  icon="CheckIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">Статус</span>
              </th>
              <td class="pb-50 text-capitalize">
                {{ userData.active===1?'Active':'Inactive' }}
              </td>
            </tr>
            <tr>
              <th class="pb-50">
                <feather-icon
                  icon="MailIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">Почта</span>
              </th>
              <td class="pb-50">
                {{ userData.email }}
              </td>
            </tr>
            <tr>
              <th>
                <feather-icon
                  icon="PhoneIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">Контакт</span>
              </th>
              <td>
                {{ userData.contact }}
              </td>
            </tr>
          </table>
        </b-col>
      </b-row>
    </b-card>
    <b-card
      no-body
    >
      <b-card-body>
        <b-card-title>Projects</b-card-title>
        <b-card-sub-title>Permission according to projects</b-card-sub-title>
      </b-card-body>
      <b-table
        striped
        responsive
        :items="permissionsData"
        class="mb-0"
      >

        <template #cell(permissions)="data">
          <b-badge
            v-for="(perm, index) in data.value"
            :key="index"
            pill
            variant="light-primary"
          >
            {{ perm.subject }}:
            <small>{{ perm.action }}</small>
          </b-badge>
        </template>
        <template #cell(balance)="data">
          <b-badge
            pill
            :variant="`light-${data.value > 0 ? 'sucess' : data.value === 0 || data.value === null ? 'primary' : 'danger'}`"
          >
            <small>{{ data.value ? data.value : 0 }}</small>
          </b-badge>
        </template>

      </b-table>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BButton, BAvatar, BRow, BCol, BBadge, BTable, BCardBody, BCardTitle, BCardSubTitle,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import useUsersList from '../users-list/useUsersList'

export default {
  components: {
    BCard, BButton, BRow, BCol, BAvatar, BBadge, BTable, BCardBody, BCardTitle, BCardSubTitle,
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
    permissionsData: {
      type: Array,
      required: true,
    },
  },
  setup() {
    const { resolveRoleVariant } = useUsersList()
    return {
      avatarText,
      resolveRoleVariant,
    }
  },
}
</script>

<style>

</style>
